import React       from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';


export default class Model extends React.Component {

	static propTypes = {
		data: PropTypes.object.isRequired,
		search: PropTypes.array.isRequired,
		active: PropTypes.bool.isRequired,
	};

	_handleClick = () => {
		window.location.href = this.props.data.url;
	};

	render() {
		const aka = this.props.data.aka ? this.props.data.aka : '';
		let wrapperClass = 'model';
		if (this.props.active) {
			wrapperClass += ' hover';
		}

		return (
			<div onClick={this._handleClick} className={wrapperClass} >
				<div>
					<img src={this.props.data.img} />
				</div>
				<div>
					<div className="model-name">
						<Highlighter
							highlightClassName="search-highlight"
							searchWords={this.props.search}
							textToHighlight={this.props.data.name}
						/>
						{this.props.data.creator && <div className="icon amateur-icon"></div>}
					</div>
					<div className="aka">
						<Highlighter
							highlightClassName="search-highlight"
							searchWords={this.props.search}
							textToHighlight={aka}
						/>
					</div>
				</div>
			</div>
		);
	}
}
