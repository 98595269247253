import React from "react";
import PropTypes from "prop-types";
import Keyword from "./Item/Keyword.jsx";
import Model from "./Item/Model.jsx";
import ModelAll from "./Item/ModelAll.jsx";

export default class Whisper extends React.Component {
	static propTypes = {
		text: PropTypes.string.isRequired,
		contentType: PropTypes.number.isRequired,
		submitForm: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			results: [],
			resultsCount: 0,
			visible: 0,
			active: -1
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.text !== this.props.text || nextProps.contentType !== this.props.contentType) {
			clearTimeout(this.t);
			if (nextProps.text !== "") {
				this.t = setTimeout(this.getData.bind(this), 300);
			} else {
				this.resetComponent();
			}
		}
	}

	getData() {
		if (typeof this.ajaxRequest.abort === "function") {
			this.ajaxRequest.abort();
		}

		this.ajaxRequest = $.ajax({
			url: "/main-search/",
			dataType: "json",
			responseType: "json",
			data: {
				categoryValue: this.props.contentType,
				term: this.props.text
			},
			success: this.autocompleteAjaxSuccess.bind(this)
		});
	}

	getModelsList(searchWord, listItems) {
		if (typeof this.state.results.models === "object") {
			this.state.results.models.forEach((item, index) => {
				const key = `model-${index}`;
				const refid = `refid-${listItems.length}`;
				const active = this.state.active === listItems.length;
				listItems.push(<Model data={item} search={searchWord} key={key} active={active} ref={refid} />);
			});
		}
		return listItems;
	}

	getAllModelsLink(listItems) {
		if (typeof this.state.results.modelsAll === "object") {
			const active = this.state.active === listItems.length;
			const refid = `refid-${listItems.length}`;
			listItems.push(<ModelAll data={this.state.results.modelsAll} active={active} key="list-all" ref={refid} />);
		}
		return listItems;
	}

	getKeywordsList(searchWord, listItems) {
		if (typeof this.state.results.keyword === "object") {
			this.state.results.keyword.forEach((item, index) => {
				const key = `keyword-${index}`;
				const refid = `refid-${listItems.length}`;
				const active = this.state.active === listItems.length;
				listItems.push(
					<Keyword
						text={item}
						search={searchWord}
						handleClick={this._handleSearchWord}
						key={key}
						active={active}
						ref={refid}
					/>
				);
			});
		}
		return listItems;
	}

	autocompleteAjaxSuccess(response) {
		let count = 0;

		if (typeof response.models === "object") {
			count += response.models.length;
		}
		if (typeof response.modelsAll === "object") {
			count += 1;
		}
		if (typeof response.keyword === "object") {
			count += response.keyword.length;
		}

		this.setState({
			results: response,
			resultsCount: count,
			visible: 1,
			active: -1
		});
	}

	resetComponent() {
		this.setState({
			results: [],
			resultsCount: 0,
			visible: 0
		});
	}

	moveHoverDown() {
		if (this.state.active + 1 < this.state.resultsCount) {
			this.setState({ active: this.state.active + 1 });
		} else {
			this.setState({ active: 0 });
		}
	}

	moveHoverUp() {
		if (this.state.active <= 0) {
			this.setState({ active: this.state.resultsCount - 1 });
		} else {
			this.setState({ active: this.state.active - 1 });
		}
	}

	handleEnter() {
		if (this.state.active === -1) {
			this._handleSearchWord(null);
		} else {
			this.refs[`refid-${this.state.active}`]._handleClick();
		}
	}

	t = 0;
	ajaxRequest = {};

	_handleSearchWord = text => {
		this.props.submitForm(text);
	};

	render() {
		const wrapperClass = this.state.visible === 1 ? "autocomplete-box" : "hidden";
		const searchWord = this.props.text.split(" ");

		const listItems = [];
		this.getModelsList(searchWord, listItems);
		this.getAllModelsLink(listItems);
		this.getKeywordsList(searchWord, listItems);

		if (listItems.length === 0) {
			return null;
		}

		return <div className={wrapperClass}>{listItems}</div>;
	}
}
