import React from "react";
import ReactDOM from "react-dom";
import Input from "./components/Input.jsx";
import Section from "./components/Section.jsx";
import Submit from "./components/Submit.jsx";
import Whisper from "./components/Whisper.jsx";

export default class Autocomplete extends React.Component {
	constructor(props) {
		/* global searchTexts searchSectionSelected */
		super(props);

		this.state = {
			search: searchTexts.value ? searchTexts.value : "",
			contentType: parseInt(searchSectionSelected, 10),
			sectionVisible: false,
			whisperVisible: false
		};
	}

	componentDidMount() {
		document.addEventListener("click", this._handleWindowClick, false);
		setTimeout(this.hideFallback, 500);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this._handleWindowClick, false);
	}

	hideFallback() {
		document.getElementById("react-search").style.display = "block";
		document.getElementById("searchForm").style.display = "none";
	}

	_handleSearch = text => {
		this.setState({
			search: text
		});
	};

	_handleWindowClick = event => {
		if (!ReactDOM.findDOMNode(this).contains(event.target)) {
			this.refs.whisper.resetComponent();
		}
	};

	_handleSubmit = text => {
		const searchText = text === null ? this.state.search : text;
		$("#searchForm input[type=text]").val(searchText.replace(/ /g, "+"));
		$("#searchForm select").val(this.state.contentType);
		$("#searchForm").submit();
	};

	_handleSectionClick = (visible, contentType) => {
		this.setState({
			contentType,
			sectionVisible: visible
		});
		return true;
	};

	_handleKeyNav = event => {
		if (event.keyCode === 13) {
			// enter
			this.refs.whisper.handleEnter();
		} else if (event.keyCode === 40) {
			// down arrow
			this.refs.whisper.moveHoverDown();
		} else if (event.keyCode === 38) {
			// up arrow
			this.refs.whisper.moveHoverUp();
		} else if (event.keyCode === 27) {
			// esc
			this.refs.whisper.resetComponent();
		}
		return true;
	};

	removeSearchValue = () => {
		this.setState({
			search: ""
		});
	};

	render() {
		return (
			<div>
				<div className="search-wrapper">
					<Input text={this.state.search} search={this._handleSearch} handleKeyNav={this._handleKeyNav} />
					{this.state.search.length > 0 && (
						<button onClick={this.removeSearchValue}>
							<i className="fa fa-times" />
						</button>
					)}
				</div>
				<Section
					selected={this.state.contentType}
					visible={this.state.sectionVisible}
					handleClick={this._handleSectionClick}
				/>
				<Submit submitForm={this._handleSubmit} />
				<Whisper
					text={this.state.search}
					contentType={this.state.contentType}
					submitForm={this._handleSubmit}
					ref="whisper"
				/>
			</div>
		);
	}
}
