import React       from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';


export default class Keyword extends React.Component {

	static propTypes = {
		handleClick: PropTypes.func.isRequired,
		text: PropTypes.string.isRequired,
		search: PropTypes.array.isRequired,
		active: PropTypes.bool.isRequired,
	};

	_handleClick = () => {
		this.props.handleClick(this.props.text);
	};

	render() {
		let wrapperClass = 'keyword';
		if (this.props.active) {
			wrapperClass += ' hover';
		}

		return (
			<div className={wrapperClass} onClick={this._handleClick} >
				<Highlighter
					highlightClassName="search-highlight"
					searchWords={this.props.search}
					textToHighlight={this.props.text}
				/>
			</div>
		);
	}
}
