import React from "react";
import PropTypes from "prop-types";

export default class Submit extends React.Component {
	static propTypes = {
		submitForm: PropTypes.func.isRequired
	};

	_handleSearch = () => {
		this.props.submitForm(null);
	};

	_renderAdvancedSearchButton = () => {
		if (advancedSearchOptions && advancedSearchOptions.visible) {
			return (
				<div className="adv-search-link">
					<a className="btn btn-grey" href="/adv-search/" title={advancedSearchOptions.title}>
						<i className="fa fa-fw fa-search-plus" />
					</a>
				</div>
			);
		}
	};

	render() {
		/* global searchTexts */
		return (
			<div>
				<button
					className="btn btn-grey search"
					type="submit"
					value={searchTexts.placeholder}
					title={searchTexts.placeholder}
					onClick={this._handleSearch}
				>
					<span className="fa fa-fw fa-search" />
				</button>
				{this._renderAdvancedSearchButton()}
			</div>
		);
	}
}
