import React   from 'react';
import PropTypes from 'prop-types';


export default class Input extends React.Component {

	static propTypes = {
		text: PropTypes.string.isRequired,
		search: PropTypes.func.isRequired,
		handleKeyNav: PropTypes.func.isRequired,
	};

	handleChange = (event) => {
		this.props.search(event.target.value);
	};

	handleKeyNav = (event) => {
		this.props.handleKeyNav(event);
		return true;
	};

	render() {
		/* global searchTexts */
		return (
			<input
				type="text"
				value={this.props.text}
				onChange={this.handleChange}
				placeholder={searchTexts.placeholder}
				onKeyUp={this.handleKeyNav}
			/>
		);
	}
}
