import React   from 'react';
import PropTypes from 'prop-types';


export default class ModelAll extends React.Component {

	static propTypes = {
		data: PropTypes.object.isRequired,
		active: PropTypes.bool.isRequired,
	};

	_handleClick = () => {
		window.location.href = this.props.data.url;
	};

	render() {
		let wrapperClass = 'models-all';
		if (this.props.active) {
			wrapperClass += ' hover';
		}

		return (
			<div className={wrapperClass} onClick={this._handleClick} >
				<b>{this.props.data.value}</b>
			</div>
		);
	}
}
