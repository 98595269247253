import React    from 'react';
import PropTypes from 'prop-types';


export default class SectionItem extends React.Component {

	static propTypes = {
		item: PropTypes.object.isRequired,
		active: PropTypes.number.isRequired,
		handleClick: PropTypes.func.isRequired,
	};

	_handleClick = () => {
		this.props.handleClick(parseInt(this.props.item.id, 10));
	};

	render() {
		let classActive = '';
		if (this.props.active === parseInt(this.props.item.id, 10)) {
			classActive = 'disabled';
		}

		return (
			<li className={classActive} onClick={this._handleClick}>
				{this.props.item.text}
			</li>
		);
	}
}
