import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import SectionItem from "./SectionItem.jsx";

export default class Section extends React.Component {
	static propTypes = {
		handleClick: PropTypes.func.isRequired,
		selected: PropTypes.number.isRequired,
		visible: PropTypes.bool.isRequired
	};

	componentDidMount() {
		document.addEventListener("click", this._handleWindowClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this._handleWindowClick, false);
	}

	_handleWindowClick = event => {
		if (searchSections.length === 1) {
			return false;
		}

		if (!ReactDOM.findDOMNode(this).contains(event.target)) {
			this.props.handleClick(false, this.props.selected);
		}
		return true;
	};

	_toggleList = () => {
		this.props.handleClick(!this.props.visible, this.props.selected);
	};

	_handleItemClick = id => {
		this.props.handleClick(false, id);
	};

	render() {
		/* global searchSections */
		if (searchSections.length === 1) {
			return null;
		}

		const list = [];
		let selectedText = "";
		searchSections.forEach(item => {
			list.push(
				<SectionItem
					key={item.id}
					handleClick={this._handleItemClick}
					active={this.props.selected}
					item={item}
				/>
			);
			if (this.props.selected === parseInt(item.id, 10)) {
				selectedText = item.text;
			}
		});

		let listVisibleClass = "";
		if (this.props.visible) {
			listVisibleClass = "active";
		}

		return (
			<div className="select-content-type">
				<div onClick={this._toggleList}>
					<span>{selectedText}</span>
					<span className="pull-right fa fa-fw fa-caret-down" />
				</div>
				<ul className={listVisibleClass}>{list}</ul>
			</div>
		);
	}
}
