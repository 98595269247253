import React from "react";
import ReactDOM from "react-dom";
import Autocomplete from "./Autocomplete.jsx";

ReactDOM.render(
	<div className="autocomplete autocomplete-js">
		<Autocomplete />
	</div>,
	document.getElementById("autocomplete-reactjs")
);
